<template>
  <AppLayout>
    <template v-slot:appContent>

      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">All Credited Payments</h2>
        </div>
        <br>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="paginationOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <label>Amount Form</label>
                <input v-model="getCreditedPaymentsParams.reference"
                       class="form-control search-product-input-element" type="text"
                       placeholder="Search a credited payment amount">
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <label>Amount To</label>
                <input v-model="getCreditedPaymentsParams.reference"
                       class="form-control search-product-input-element" type="text"
                       placeholder="Search a credited payment amount">
              </div>

            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getCreditedPaymentsParams.reference"
                       class="form-control search-product-input-element" type="text"
                       placeholder="Search a credited payment by reference">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyPaymentsFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">CUSTOMER</th>
                    <th class="position-relative" :style="{width:'12%'}">ADMIN</th>
                    <th class="position-relative" :style="{width:'12%'}">REFERENCE</th>
                    <th class="position-relative" :style="{width:'10%'}">AMOUNT</th>
                    <th class="position-relative" :style="{width:'10%'}">COMMENT</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(collection) in creditedPayments" :key="collection.id">
                    <td>
                      <div v-if="collection.creditedTo && collection.paidBycreditedTo !== null">
                        <p class="mb-0 text-secondary text-capitalize">
                          {{ `${collection.creditedTo.first_name} ${collection.creditedTo.last_name}` }}</p>
                        <div>
                          <small>{{ userFullAddress(collection.creditedTo.customer) }}</small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="collection.creditedBy && collection.creditedBy !== null">
                        <p class="mb-0 text-secondary text-capitalize">
                          {{ `${collection.creditedBy.first_name} ${collection.creditedBy.last_name}` }}</p>
                        <div>
                          <small>{{ collection.code }}</small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p>{{ collection.reference }}</p>
                    </td>
                    <td>
                      <p> $ {{ parseFloat(collection.amount / 100).toFixed(2) }}</p>
                    </td>
                    <td>
                      <p v-html="collection.comment"></p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

import {mapActions, mapGetters} from "vuex";

export default {
  name: "CreditedPayment",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
  },
  data() {
    return {
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },
      getCreditedPaymentsParams: {
        with_relation: ['creditedTo.customer.address', 'creditedBy']
      },
    }
  },
  watch: {
    selectedPagination: function (pagination) {
      this.getCreditedPaymentsParams.pagination = pagination.value;
    },
  },
  computed: {
    ...mapGetters({
      creditedPayments: 'appCreditedPayments/creditedPayments',
      paginateLinks: 'appCreditedPayments/paginateLinks',
    }),
    /* userFullName() {
       let dataArray = {};
       this.payments.map((payment) => {
         if (payment.type === 'Card' && payment.cardPayment !== null) {
           dataArray[payment.id] = `${payment.cardPayment.paidBy.first_name} ${payment.cardPayment.paidBy.last_name}`
         }
         if (payment.type === 'Cash' && payment.cashPayment !== null) {
           dataArray[payment.id] = `${payment.cashPayment.paidBy.first_name} ${payment.cashPayment.paidBy.last_name}`
         }
         if (payment.type === 'EFTPOS' && payment.eftPayment !== null) {
           dataArray[payment.id] = `${payment.eftPayment.paidBy.first_name} ${payment.eftPayment.paidBy.last_name}`
         }
       });
       return dataArray;
     },*/
    paginationOptions() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getCreditedPayments: 'appCreditedPayments/getCreditedPayments',
    }),

    async getCreditedPaymentList(paymentsParams) {
      this.loader(true);
      await this.getCreditedPayments(paymentsParams)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.loader(false);
              this.showToastMessage(response);
            } else {
              this.loader(false);
            }
          });
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async applyPaymentsFilter(pageNumber) {
      this.getCreditedPaymentsParams.page = pageNumber;
      await this.getCashPaymentList(this.getCreditedPaymentsParams);
    },
    userFullAddress(customerObj) {
      let businessName = customerObj.address?.business?.name ?? '';
      let addressStreet = customerObj.address.street;
      let addressSuburb = customerObj.address.suburb;
      let addressState = customerObj.address.state.toUpperCase();
      let addressPostCode = customerObj.address.post_code;
      let addressCountry = customerObj.address.country;
      return customerObj.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },
  },
  mounted() {
    this.getSettingList(this.getSettingsParams);
    this.getCreditedPaymentList(this.getCreditedPaymentsParams);
  }

}
</script>

<style scoped>
.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
</style>
